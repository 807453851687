import React from 'react';
import Lottie from 'react-lottie';
import PaymentAnimation from '../../payment_animation.json';

const StepThreeContent = () => {
    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: PaymentAnimation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <>
            <Lottie
                options={defaultOptions}
                height={150}
                width={150}
            />
            <p style={{marginBottom: '20px',marginTop: '10px', textAlign: 'center'}}>
                Vedd el a készpénzt, vagy kezeld le az ügyfél fizetését bankkártyával.
            </p>
        </>
    );
};

export default StepThreeContent;
