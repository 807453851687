import React from 'react';

const DiscountList = ({ extraDiscounts }) => {
    // Ellenőrizd, hogy az extraDiscounts változó null vagy undefined-e, és ha igen, adj vissza egy fallback elemet
    if (!extraDiscounts) {
        return <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: '15px' }}>
            <div style={{ width: '50%', fontWeight: 'bold' }}>
                Extra kedvezmény:
            </div>
            <div style={{ width: '50%' }}>
                Nem találahtó extra kedvezmény!
            </div>
        </div>
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: '15px' }}>
            <div style={{ width: '50%', fontWeight: 'bold' }}>
                Extra kedvezmény:
            </div>
            <div style={{ width: '50%' }}>
                {extraDiscounts.map((extraDiscount, index) => (
                    <p key={index}>{extraDiscount.text}: {extraDiscount.discount}%</p>
                ))}
            </div>
        </div>
    );
};

export default DiscountList;
