import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom'; // Importáld a useLocation hook-ot
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {logout, selectIsLoggedIn, selectUser} from '../store/loginSlice';

const theme = createTheme({
    palette: {
        primary: {
            main: '#3d5a80',
        },
        secondary: {
            main: '#fff',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    transition: 'none', // Disable transitions for the button
                },
            },
        },
    },
});

const Header = () => {
    const dispatch = useDispatch();
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const user = useSelector(selectUser);
    const location = useLocation(); // Használd a useLocation hook-ot az aktuális útvonal eléréséhez

    const handleLogout = () => {
        dispatch(logout());
        // Töröljük a felhasználót a localstorage-ból is, ha szükséges
        // localStorage.removeItem('loginState');
    };

    return (
        <ThemeProvider theme={theme}>
            {isLoggedIn ? (
                <Box className='menu'
                >
                    <div className='loginNameWrapper'>
                        <p className="logged-in-name">
                            Bejelentkezve:
                        </p>
                        <span className="name">
                            {user[0].last_name} {user[0].first_name}
                        </span>
                    </div>
                    <Button color="secondary" variant="outlined" onClick={handleLogout}>
                        Kijelentkezés
                    </Button>
                </Box>
            ) : (
                <Box
                    className='menu'
                >
                    {location.pathname !== '/register' && ( // Ha az aktuális útvonal nem '/register', akkor jelenítsd meg a gombot
                        <Button color="secondary" variant="outlined" onClick={() => {
                            window.location.href = '/register';
                        }}>
                            Regisztráció
                        </Button>
                    )}
                </Box>
            )}

        </ThemeProvider>
    );
};

export default Header;
