import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const loginSlice = createSlice({
    name: 'login',
    initialState: {
        user: null,
        isLoggedIn: false,
    },
    reducers: {
        loginSuccess: (state, action) => {
            state.isLoggedIn = true;
            state.user = action.payload;
        },
        logout: (state) => {
            state.isLoggedIn = false;
            state.user = null;
        },
    },
});

export const { loginSuccess, logout } = loginSlice.actions;

export const selectIsLoggedIn = (state) => state.login.isLoggedIn;
export const selectUser = (state) => state.login.user;

export const validateLogin = (email, code) => async (dispatch) => {
    try {
        const response = await axios.post('https://app.kvery.io/query/api/120da4fb5c6/login/validate', {
            user_email: email,
            token: code,
        });

        if (response.data && response.data.status === 1) {
            const user = response.data.response;
            dispatch(loginSuccess(user));
            saveLoginStateToLocalStorage({ isLoggedIn: true, user });
        } else {
            // Handle error when user validation fails
            // For example, you can show an error message.
        }
    } catch (error) {
        // Handle error from API call
        // For example, you can show an error message.
    }
};

export const saveLoginStateToLocalStorage = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('loginState', serializedState);
    } catch (error) {
        // Handle errors here
    }
};

export const loadLoginStateFromLocalStorage = () => {
    try {
        const serializedState = localStorage.getItem('loginState');
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (error) {
        return undefined;
    }
};

export default loginSlice.reducer;
