import React from 'react';

const Exceptions = ({clubExceptions}) => {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '15px'
        }}>
            <div style={{width: '50%', color: '#ED535C', fontWeight: 'bold'}}>
                Kivételek:
            </div>
            <div style={{width: '50%'}}>
                {clubExceptions}
            </div>
        </div>
    );
};


export default Exceptions;