import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CheckTable from './CheckTable';
import DiscountList from './DiscountList';
import Exceptions from './Exceptions';
import blueLogo from "../../sanitas-logo_blue.svg";

const CheckResults = ({ cardData, institutionData }) => {
    if (!institutionData) {
        // Visszatérhetünk egy töltőkomponenssel vagy egy hibaüzenettel
        return <div>Betöltés...</div>;
    }
    return (
        <>
            <Box sx={{display: 'flex', alignItems: 'center', height: '10vh', justifyContent: 'center'}}>
                <img className="blueLogo" src={blueLogo} alt="blueLogo"/>
            </Box>
            <CheckTable label="Kártyaszám" value={cardData?.card_id}/>
            <CheckTable label="Kártyabirtokos" value={cardData?.customer_name}/>
            <CheckTable label="Alap kedvezmény" value={`${institutionData.base_discount}%`}/>
            <DiscountList extraDiscounts={institutionData.extra_discounts}/>
            <Exceptions clubExceptions={institutionData.club_exceptions}/>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '10px',
                justifyContent: 'space-between',
                marginTop: '25px'
            }}>
                <Button style={{marginTop: '25px', width: '45%'}} variant="outlined" onClick={() => {
                    window.location.href = '/';
                }}>Vissza</Button>
                <div style={{
                    marginTop: '25px',
                    width: '45%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: '#088651',
                    color: '#fff',
                    borderRadius: '5px'
                }}>ÉRVÉNYES
                </div>
            </div>
        </>
    );
};

export default CheckResults;
