import React from 'react';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Lottie from 'react-lottie';
import MailAnimation from '../mail_animation.json'

const EmailSendSuccessFully = ({ label, value }) => {
    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: MailAnimation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <>
                <Box
                className='box-success'
                    sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: 'column',
                    bgcolor: '#fff',
                    minHeight: '350px',
                    width: '460px',
                    borderRadius: '8px',
                    justifyContent: 'center',
                    alignContent: 'center',
                    padding:'25px'
                }}>
                        <>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', justifyContent: 'center', width: '100%' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', justifyContent: 'center', width: '100%' }}>
                                <Lottie
                                    options={defaultOptions}
                                    height={100}
                                    width={100}
                                />
                            </div>

                            <p className="boxTitle">Sikeresen elküldve!</p>
                            <p>Az ügyfél pár percen belül megkapja a tájékoztató e-mailt.</p>
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', justifyContent: 'center', width: '100%' }}>
                                <Button style={{ marginTop: '25px', width: '80%', transformText:'uppercase',justifyContent: 'center' }} variant="outlined" onClick={() => { window.location.href = '/'; }}>Vissza a kezdőoldalra</Button>
                            </div>
                            </div>
                        </>
                </Box>
        </>
    );
};

export default EmailSendSuccessFully;