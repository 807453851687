import React from 'react';
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { MuiTelInput } from "mui-tel-input";

const StepOneContent = ({ email, phone, onEmailChange, onPhoneChange }) => {
    console.log(email, phone);

    return (
        <Box style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }}>
            <p style={{ textAlign: 'center', fontWeight:'bold' }}>
                Kérlek add meg az ügyfél adatait:
            </p>
            <TextField
                id="standard-basic"
                helperText="E-mail cím"
                variant="standard"
                value={email}
                onChange={onEmailChange}
            />
            <MuiTelInput
                style={{ marginTop: '15px', padding: '0px' }}
                helperText="Telefonszám"
                value={phone}
                onChange={onPhoneChange}
                defaultCountry="HU"
            />
        </Box>
    );
};

export default StepOneContent;
