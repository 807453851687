import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { logout, selectIsLoggedIn, selectUser } from '../store/loginSlice';

const theme = createTheme({
    palette: {
        primary: {
            main: '#3d5a80',
        },
        secondary: {
            main: '#fff',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    transition: 'none', // Disable transitions for the button
                },
            },
        },
    },
});

const HamburgerMenu = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const dispatch = useDispatch();
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const user = useSelector(selectUser);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        dispatch(logout());
        // Töröljük a felhasználót a localstorage-ból is, ha szükséges
        // localStorage.removeItem('loginState');
        handleMenuClose();
    };

    return (
        <ThemeProvider theme={theme}>
            <div className='hamb'>
                <IconButton
                    size="large"
                    edge="end"
                    color="secondary" // Használd a "secondary" palettát a fehér ikonért
                    aria-label="menu"
                    aria-controls="menu"
                    aria-haspopup="true"
                    onClick={handleMenuOpen}
                >
                    <MenuIcon />
                </IconButton>
                <Menu
                    id="menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                >
                    {/* Ide tedd az egyes menüpontokat */}
                    {isLoggedIn ? (
                        <>
                            <MenuItem disabled>{`Bejelentkezve: ${user[0].last_name} ${user[0].first_name}`}</MenuItem>
                            <MenuItem onClick={handleLogout}>Kijelentkezés</MenuItem>
                        </>
                    ) : (
                        <MenuItem onClick={() => (window.location.href = '/register')}>
                            Regisztráció
                        </MenuItem>
                    )}
                </Menu>
            </div>
        </ThemeProvider>
    );
};

export default HamburgerMenu;