import React from 'react';
import Box from '@mui/material/Box';


const StepFourContent = ({ email, phone }) => {
    console.log("email prop:", email);
    console.log("phone prop:", phone);

    return (
        <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '20px' }}>
            <p style={{ textAlign: 'center' }}>
                Ellenőrizd, hogy az adatok helyesek-e!
            </p>
            <span style={{fontWeight:'bold'}}>{email}</span>
            <span style={{fontWeight:'bold',marginTop:'15px'}}>{phone}</span>
        </Box>
    );
};

export default StepFourContent;
