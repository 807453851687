import React, {useEffect, useState} from 'react';
import './App.css';
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import Check from './pages/Check';
import FAQ from './pages/FAQ';
import CardProposal from './pages/CardProposal';
import Sales from './pages/Sales';
import HamburgerMenu from './components/HamburgerMenu';
import {selectIsLoggedIn} from './store/loginSlice';
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Lottie from 'react-lottie';
import AnnouncementAnimation from './announcement_animation.json';

function App() {
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const [modalOpen, setModalOpen] = useState(false);
    const [unreadMessages, setUnreadMessages] = useState([]);
    const [userId, setUserId] = useState(null);
    const user = useSelector((state) => state.login.user && state.login.user.length > 0 ? state.login.user[0] : null);


    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: AnnouncementAnimation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };


    useEffect(() => {
        if (isLoggedIn && user && user.user_hash) { // ellenőrzés, hogy a felhasználó be van-e jelentkezve
            fetch(`https://app.kvery.io/query/api/16c74d97b01/get-user-id?hash=${user.user_hash}`)
                .then((response) => response.json())
                .then((data) => {
                    if (data.status === 1 && data.response.length > 0) {
                        setUserId(data.response[0].id);
                    }
                })
                .catch((error) => {
                    console.error('Error fetching user_id:', error);
                });
        }
    }, [user?.user_hash, isLoggedIn]); // az isLoggedIn hozzáadása a függőségi tömbhöz


    useEffect(() => {
        if (userId) {
            // Fetch unread messages based on user_id
            fetch(`https://app.kvery.io/query/api/16c74d97b01/messages?user_id=${userId}`)
                .then((response) => response.json())
                .then((data) => {
                    if (data.status === 1 && data.response.length > 0) {
                        const unreadMessages = data.response;
                        setUnreadMessages(unreadMessages);
                        if (unreadMessages.length > 0) {
                            setModalOpen(true); // Open the modal if there are unread messages
                        }
                        console.log(userId);
                    }
                })
                .catch((error) => {
                    console.error('Error fetching unread messages:', error);
                });
        }
    }, [userId]);



    const handleReadMessage = (messageId) => {
        // Mark message as read by posting to the API
        fetch('https://app.kvery.io/query/api/16c74d97b01/messages/read', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ user_id: userId, message_id: messageId }),
        })
            .then(() => {
                // After successful POST, close the modal
                setModalOpen(false);
                // Update the unreadMessages state to remove the read message from the list
                setUnreadMessages((prevUnreadMessages) => prevUnreadMessages.filter((message) => message.id !== messageId));
            })
            .catch((error) => {
                console.error('Error marking message as read:', error);
            });
    };

    return (
        <>
            <Router>
                <HamburgerMenu/>
                <Header/>
                <Routes>
                    {!isLoggedIn ? (
                        <>
                            {/* If the user is not logged in, only the /login and /register pages are accessible */}
                            <Route path="/" element={<Login/>}/>
                            <Route path="/login" element={<Login/>}/>
                            <Route path="/register" element={<Register/>}/>
                            <Route path="*" element={<Navigate to="/login" replace/>}/>
                        </>
                    ) : (
                        <>
                            {/* If the user is logged in, the following pages are accessible */}
                            <Route path="/" element={<Home/>}/>
                            <Route path="/check" element={<Check/>}/>
                            <Route path="/faq" element={<FAQ/>}/>
                            <Route path="/proposal" element={<CardProposal/>}/>
                            <Route path="/sales" element={<Sales/>}/>
                            {/* Redirect /login to / if the user is already logged in */}
                            <Route path="/login" element={<Navigate to="/" replace/>}/>
                            {/* If a non-existent page is navigated, redirect to / */}
                            <Route path="*" element={<Navigate to="/" replace/>}/>
                        </>
                    )}
                </Routes>
            </Router>
            <Footer/>

            <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <div className='announcementModal'>
                        {unreadMessages.map((message) => (

                            <div key={message.id} style={{ textAlign: 'center' }}>
                                <Lottie
                                    options={defaultOptions}
                                    height={150}
                                    width={150}
                                />
                                <h2>{message.title}</h2>
                                <p style={{textAlign:'left'}}>{message.content}</p>
                                <Button variant="outlined" color="primary" onClick={() => handleReadMessage(message.id)}>Elolvastam</Button>
                            </div>
                        ))}
                    </div>
                </div>
            </Modal>


        </>
    );
}

export default App;
