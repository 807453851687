import React from 'react';
import Box from '@mui/material/Box';

const OptionContainer = ({ children }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'column',
                bgcolor: '#fff',
                width: '580px',
                borderRadius: '8px',
                justifyContent: 'center',
                alignContent: 'center',
                padding: '25px 10px',
            }}
            className='homeWrapper'
        >
            {children}
        </Box>
    );
};

export default OptionContainer;
