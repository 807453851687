import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQItem = ({ summary, details }) => {
    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography style={{ fontWeight: '400' }}>{summary}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography style={{ fontWeight: '200' }}>{details}</Typography>
            </AccordionDetails>
        </Accordion>
    );
};

const FAQAccordion = ({ faqData, isReseller }) => {
    if (isReseller) {
        const resellerFAQs = faqData.filter((item) => item.isResellerFAQ === 1);

        return (
            <>
                <p className="boxTitle">Értékesítői gyakori kérdések:</p>
                {resellerFAQs.map((item) => (
                    <FAQItem key={item.id} summary={item.faq_title} details={item.faq_description} />
                ))}
            </>
        );
    } else {
        const regularFAQs = faqData.filter((item) => item.isResellerFAQ !== 1);

        return (
            <>
                <p className="boxTitle">Gyakori kérdések:</p>
                {regularFAQs.map((item) => (
                    <FAQItem key={item.id} summary={item.faq_title} details={item.faq_description} />
                ))}
            </>
        );
    }
};



export default FAQAccordion;
