import React, {useState} from 'react';
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import StepOneContent from '../components/Sales/StepOneContent';
import StepTwoContent from '../components/Sales/StepTwoContent';
import StepThreeContent from '../components/Sales/StepThreeContent';
import StepFourContent from '../components/Sales/StepFourContent';
import ConfirmationModal from '../components/Sales/ConfirmationModal';
import {useSelector} from 'react-redux';
import EmailSendSuccessFully from "../components/EmailSendSuccessFully"; // Import the EmailSendSuccessFully component

const steps = ['Adatok rögzítése', 'Számla kiállítása', 'Fizetés', 'Összegzés'];

const Sales = () => {
    const user = useSelector((state) => state.login.user[0]);
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [confirmationFinalized, setConfirmationFinalized] = useState(false);
    const isStepOneComplete = email.trim() !== '' && phone.trim() !== '';


    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        if (activeStep === 0) {
            window.location.href = '/'; // Redirect to home if in the 0th step
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };

    const handleConfirmationOpen = () => {
        setOpenConfirmation(true);
    };

    const handleConfirmationClose = () => {
        setOpenConfirmation(false);
    };

    const handleFinalize = () => {
        setConfirmationFinalized(true);
        handleConfirmationClose();
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePhoneChange = (newValue) => {
        setPhone(newValue);
    };

    return (
        <Container sx={{display: 'flex', alignItems: 'center', height: '80vh', justifyContent: 'center'}}>
            <Box sx={{
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'column',
                bgcolor: '#fff',
                width: '460px',
                borderRadius: '8px',
                justifyContent: 'center',
                alignContent: 'center',
                padding: '25px'
            }}>
                {!confirmationFinalized && !openConfirmation && (
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label, index) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                )}
                <div>
                    {confirmationFinalized ? (
                        <EmailSendSuccessFully/>
                    ) : (
                        <>
                            {activeStep === steps.length ? (
                                <Typography>All steps completed</Typography>
                            ) : (
                                <>
                                    {activeStep === 0 && (
                                        <StepOneContent
                                            email={email}
                                            phone={phone}
                                            onEmailChange={handleEmailChange}
                                            onPhoneChange={handlePhoneChange}
                                        />
                                    )}
                                    {activeStep === 1 && <StepTwoContent/>}
                                    {activeStep === 2 && <StepThreeContent/>}
                                    {activeStep === 3 && <StepFourContent email={email} phone={phone}/>}
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '10px',
                                        justifyContent: 'space-between',
                                        width: '100%'
                                    }}>
                                        <Button
                                            variant="outlined"
                                            style={{marginTop: '25px', width: '45%'}}
                                            onClick={handleBack}>
                                            Vissza
                                        </Button>
                                        <Button
                                            style={{marginTop: '25px', width: '45%'}}
                                            variant="contained" color="primary"
                                            onClick={activeStep === steps.length - 1 ? handleConfirmationOpen : handleNext}
                                            disabled={activeStep === 0 && !isStepOneComplete} // Disable Next button in StepOne if email or phone is empty
                                        >
                                            {activeStep === steps.length - 1 ? 'Véglegesítés' : 'Következő'}
                                        </Button>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </div>

                {/* Confirmation Modal */}
                <ConfirmationModal
                    open={openConfirmation}
                    onClose={handleConfirmationClose}
                    onConfirm={handleFinalize}
                    email={email}
                    phone={phone}
                    userHash={user ? user.user_hash : ''}
                />
            </Box>
        </Container>
    );
};

export default Sales;