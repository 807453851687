import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import loginReducer, { loadLoginStateFromLocalStorage, saveLoginStateToLocalStorage } from './store/loginSlice';
import App from './App';

const persistedLoginState = loadLoginStateFromLocalStorage();

const store = configureStore({
    reducer: {
        login: loginReducer,
    },
    preloadedState: {
        login: persistedLoginState,
    },
});

// Save login state to local storage whenever the state changes
store.subscribe(() => {
    const { login } = store.getState();
    saveLoginStateToLocalStorage(login);
});

const root = createRoot(document.getElementById('root'));

root.render(
    <Provider store={store}>
        <App />
    </Provider>
);
