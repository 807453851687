import React from 'react';
import Box from '@mui/material/Box';

const CardOption = ({ icon, title, onClick }) => {
    return (
        <Box
            style={{
                display: 'flex',
                flexDirection: 'row',
                borderRadius: '8px',
                border: '1px solid #3D5A80',
                marginTop: '10px',
                alignItems: 'center',
                minHeight: '100px',
                width: '483px',
                cursor: 'pointer',
            }}
            className='card-option'
            onClick={onClick}
        >
            {icon}
            <p className="cardTitle">{title}</p>
        </Box>
    );
};

export default CardOption;
