import React, { useRef, useEffect, useCallback, useState } from 'react';
import { TextField } from '@mui/material';

const LoginCheck = ({ setVerificationCode }) => {
    const inputRefs = useRef([]);
    const numInputs = 6;
    const [code, setCode] = useState(Array(numInputs).fill(''));

    const isValidInput = (input) => {
        const regex = /^\d{1}$/;
        return regex.test(input);
    };

    const handleChange = (event, index) => {
        const value = event.target.value;
        if (isValidInput(value)) {
            setCode(prevCode => {
                const newCode = [...prevCode];
                newCode[index] = value;
                setVerificationCode(newCode.join(''));
                return newCode;
            });

            if (index < numInputs - 1 && value !== "") {
                inputRefs.current[index + 1].focus();
            }
        }
    };

    const handleKeyDown = useCallback((event, index) => {
        if (event.key === "Backspace") {
            if (index > 0 && event.target.value === "") {
                const prevInputRef = inputRefs.current[index - 1];
                prevInputRef.focus();
                event.preventDefault();
            }
        }
    }, []);

    useEffect(() => {
        const currentRefs = inputRefs.current;

        const handleKeyDownWithIndex = (event, index) => handleKeyDown(event, index);

        currentRefs.forEach((el, index) => {
            if (el) {
                el.addEventListener("keydown", (event) => handleKeyDownWithIndex(event, index));
            }
        });

        return () => {
            currentRefs.forEach((el, index) => {
                if (el) { // Itt ellenőrizzük, hogy az elem létezik-e
                    el.removeEventListener("keydown", (event) => handleKeyDownWithIndex(event, index));
                }
            });
        };
    }, [handleKeyDown]);


    return (
        <div
            className='check-input'
            style={{ display: 'flex', flexDirection: 'row', gap: '20px', width: '70%', justifyContent:'center',alignItems:'center' }}>
            {Array.from({ length: numInputs }).map((_, index) => (
                <TextField
                    className='check-input'
                    style={{ justifyContent: 'center', alignItems: 'center',textAlign:'center' }}
                    key={index}
                    inputRef={(el) => (inputRefs.current[index] = el)}
                    variant="outlined"
                    size="small"
                    margin="dense"
                    type="text"
                    inputProps={{ maxLength: 1 }}
                    onChange={(e) => handleChange(e, index)}
                    value={code[index]}
                />
            ))}
        </div>
    );
};

export default LoginCheck;
