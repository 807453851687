import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import LoginForm from '../components/Login/LoginForm';
import LoginCheck from '../components/Login/LoginCheck';
import {useDispatch} from 'react-redux';
import {validateLogin} from '../store/loginSlice';
import {useNavigate} from 'react-router-dom';

const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [isError, setIsError] = useState(false);
    const [isNotFound, setIsNotFound] = useState(false);
    const [isNextButtonClicked, setIsNextButtonClicked] = useState(false);
    const [verificationCode, setVerificationCode] = useState('');
    const dispatch = useDispatch();

    const handleLogin = () => {
        dispatch(validateLogin(email, verificationCode));
        navigate('/');
    };

    const handleNextButtonClick = () => {
        if (isValidEmail(email)) {
            const requestBody = {
                user_email: email
            };

            fetch(`https://app.kvery.io/query/api/120da4fb5c6/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody) // Stringify the entire object
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.status === 1) {
                        setIsError(false);
                        setIsNextButtonClicked(true);
                    } else {
                        setIsNotFound(true);
                    }
                })
                .catch(() => {
                    setIsError(true);
                });
        } else {
            setIsError(true);
        }
    };

    const handleCloseSnackbar = () => {
        setIsError(false);
        setIsNotFound(false);
    };

    const isValidEmail = (email) => {
        const emailRegex = /\S+@\S+\.\S+/;
        return emailRegex.test(email);
    };

    return (
        <>
            <Container sx={{display: 'flex', alignItems: 'center', height: '80vh', justifyContent: 'center'}}>
                <Box sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: 'column',
                    bgcolor: '#fff',
                    minHeight: '300px',
                    width: '460px',
                    borderRadius: '8px',
                    justifyContent: 'center',
                    alignContent: 'center',
                    padding: '35px'
                }}>
                    {!isNextButtonClicked ? (
                        <>
                            <p className="boxTitle">Jelentkezzen be az alkalmazás használatához:</p>
                            <LoginForm email={email} setEmail={setEmail}/>
                            <Button onClick={handleNextButtonClick} style={{marginTop: '30px'}}
                                    variant="contained">Tovább</Button>
                        </>
                    ) : (
                        <>
                            <p className="boxTitle">Kérjük adja meg az emailben kapott ellenőrző kódot:</p>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginBottom: '30px'
                            }}>
                                <LoginCheck setVerificationCode={setVerificationCode}/>
                            </div>
                            <Button style={{marginTop: '10px'}} variant="contained"
                                    onClick={handleLogin}>Bejelentkezés</Button>
                        </>
                    )}
                </Box>
            </Container>

            <Snackbar open={isError} autoHideDuration={5000} onClose={handleCloseSnackbar}>
                <MuiAlert elevation={6} variant="filled" onClose={handleCloseSnackbar} severity="error">
                    A megadott e-mail cím nem megfelelő!
                </MuiAlert>
            </Snackbar>

            <Snackbar open={isNotFound} autoHideDuration={5000} onClose={handleCloseSnackbar}>
                <MuiAlert elevation={6} variant="filled" onClose={handleCloseSnackbar} severity="error">
                    A megadott e-mail cím nem található!
                </MuiAlert>
            </Snackbar>
        </>
    );
};

export default Login;
