import React from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const CheckForm = ({ cardId, handleCardIdChange, handleCheckClick }) => {
    return (
        <>
            <p className="boxTitle">Kérem adja meg a kártyaszámot:</p>
            <TextField
                id="cardId"
                label="Kártyaszám"
                variant="standard"
                value={cardId}
                onChange={handleCardIdChange}
            />
            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', justifyContent: 'space-between', width: '80%' }}>
                <Button style={{ marginTop: '25px', width: '45%' }} variant="outlined" onClick={() => { window.location.href = '/'; }}>Vissza</Button>
                <Button style={{ marginTop: '25px', width: '45%' }} variant="contained" onClick={handleCheckClick}>
                    Ellenőrzés
                </Button>
            </div>
        </>
    );
};

export default CheckForm;
