// ConfirmationModal.js

import React from 'react';
import Box from "@mui/material/Box";
import Modal from '@mui/material/Modal';
import Button from "@mui/material/Button";
import axios from 'axios';
import Lottie from 'react-lottie';
import AlertAnimation from '../../alert_animation.json';

const ConfirmationModal = ({ open, onClose, onConfirm, email, phone, userHash }) => {
    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: AlertAnimation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    const handleConfirmAndSubmit = () => {
        onConfirm(); // Close the modal
        // Send POST request to the API endpoint
        axios.post('https://app.kvery.io/query/api/120da4fb5c6/sell', {
            email: email,
            phone: phone,
            user_hash: userHash,
        })
            .then(response => {
                // Handle the response if needed
                console.log(response.data);
            })
            .catch(error => {
                // Handle the error if needed
                console.error(error);
            });
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: '#fff',
                width: 400,
                padding: '25px',
                borderRadius: '8px',
                boxShadow: 24,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}>
                <Lottie
                    options={defaultOptions}
                    height={100}
                    width={100}
                />
                <p className="boxTitle">
                    Biztosan véglegesíti? Ez a művelet nem visszavonható!
                </p>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%',marginTop:'20px' }}>
                    <Button variant="outlined" onClick={onClose}>Mégse</Button>
                    <Button variant="contained" onClick={handleConfirmAndSubmit}>Megerősít</Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ConfirmationModal;
